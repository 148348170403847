import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
// app/root.tsx
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { createHead } from 'remix-island';
import { cssBundleHref } from '@remix-run/css-bundle';
import { Fragment, useEffect, useLayoutEffect, useRef } from 'react';
import {
  UploadHandler,
  unstable_parseMultipartFormData,
  type ActionFunction,
  type LinksFunction,
  LoaderFunction,
  MetaFunction } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  redirect,
  useLocation,
  useNavigation,
  useRouteError,
  useLoaderData } from
'@remix-run/react';

import reactToastifyStyles from 'react-toastify/dist/ReactToastify.css';
import globalStyles from 'flow-ui-library/src/global.styles.css';
import appStyles from 'flow-ui-library/dist/bundle.esm.css';

import {
  OwnErrorBoundary,
  OnlineStatusProvider,
  ToastNotification } from
'flow-ui-library';

import { destroySession, getSession } from './session';
import { GaPageview } from '~/utils';
import { requireAuth } from '~/firebase';
import {
  CustomError,
  UserUploadAvatar,
  createChat,
  deleteChatById,
  sendInvite,
  userUpdate,
  AgentType,
  getChatPagination } from
'./api';
import { getAgent } from './utils/agent-router';

export const loader: LoaderFunction = async ({ request }) => {
  return await getAgent({ request });
};

export const action: ActionFunction = async ({ request }) => {
  try {
    const session = await getSession(request.headers.get('Cookie'));

    const response = await requireAuth(request);
    if (response instanceof Response) {
      return response;
    }

    const clonedData = request.clone();
    const formData = await clonedData.formData();
    const actionType = formData.get('_action');

    switch (actionType) {
      case 'new-chat':{
          const newChat = { name: 'new chat', user_id: response?.uid };
          const newChatResponse = await createChat(newChat, session.data.idToken);
          return redirect(`/chat/${newChatResponse.chat_id}`);
        }
      case 'delete-chat':{
          const chatId = formData.get('chat_id');
          const pathname = formData.get('pathname');
          const isCurrentPage = pathname === `/chat/${chatId}`;
          if (chatId) {
            await deleteChatById(String(chatId), session.data.idToken);
            if (isCurrentPage)
            return json({ success: true, isCurrentPage: true });
          }
          return json({ success: true });
        }
      case 'send-invite':{
          const inviteTo = formData.get('invite-email');
          await sendInvite(
            { to_email: inviteTo, to_name: 'testing', group: 'testing' },
            session.data.idToken
          );
          return json({ succcess: true });
        }
      case 'update-profile':{
          const name = formData.get('name');
          const orgName = formData.get('org_name');
          const website = formData.get('website');
          const avatar = formData.get('avatar');

          if (avatar instanceof File && avatar.size !== 0) {
            const uploadHandler: UploadHandler = async ({
              name,
              data,
              filename
            }) => {
              if (name !== 'avatar') {
                return undefined;
              }

              /**
               * Converts an async iterable to a buffer.
               * @param stream - The async iterable.
               * @returns A buffer.
               */
              async function streamToBuffer(
              stream: AsyncIterable<Uint8Array>)
              : Promise<Buffer> {
                const chunks: Uint8Array[] = [];
                for await (const chunk of stream) {
                  chunks.push(chunk);
                }
                return Buffer.concat(chunks);
              }

              // Convert the stream to a buffer
              const fileBuffer = await streamToBuffer(data);

              // Prepare FormData to send to the upload endpoint
              const formData = new FormData();
              formData.append('file', new Blob([fileBuffer]), filename);

              await UserUploadAvatar(
                formData,
                response?.uid,
                session.data.idToken
              );

              return filename;
            };

            await unstable_parseMultipartFormData(request, uploadHandler);
          }

          await userUpdate(
            { name, org_name: orgName, website },
            session.data.idToken
          );
          return json({ succcess: true });
        }
      case 'update-my-exp':{
          const bio = formData.get('bio');
          const integrations = formData.getAll('integrations[]');
          const metadata = { integrations };
          await userUpdate({ bio, metadata }, session.data.idToken);
          return json({ succcess: true });
        }
      case 'send-openai-api-key':{
          const OpenAikey = formData.get('openai-api-key');
          const keys = { openai_api_key: OpenAikey };
          await userUpdate({ keys }, session.data.idToken);
          return json({ succcess: true });
        }
      case 'logout':{
          return redirect('/login', {
            headers: { 'Set-Cookie': await destroySession(session) }
          });
        }
      case 'show-more-chat':{
          const startAfterId = (formData.get('start_after_id') as string) || '';
          const response = await getChatPagination(
            session.data.idToken,
            startAfterId
          );
          return json(response);
        }

      default:
        return json({});
    }
  } catch (error) {
    let errorMessage = 'Unknown error';
    let errorType;
    if (error instanceof CustomError) {
      errorMessage = error.message;
      errorType = error.type;
    }
    return { errorAction: { message: errorMessage, type: errorType } };
  }
};

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
{ rel: 'stylesheet', href: globalStyles },
{ rel: 'stylesheet', href: appStyles },
{ rel: 'stylesheet', href: reactToastifyStyles }];


export const meta: MetaFunction<typeof loader> = ({ data }) => [
{
  title: data?.agent?.name ?
  `${data?.agent?.name} - ${data?.agent?.name}` :
  'Flow AI Agent - Flow AI Agent'
}];


export const Head = createHead(
  () => {
    useLayoutEffect(() => {
      const titleElements = document.querySelectorAll('head > title');

      if (titleElements.length > 1) {
        titleElements[0].remove();
      }
    }, []);

    const { agent }: {agent?: AgentType;} = useLoaderData();

    return (
      <>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content={
          agent?.description || 'Web3, Data, and AI Tools for Startups'} />


        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={agent?.imageFavicon32Url || '/favicon/favicon-32x32.png'} />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={agent?.imageFavicon16Url || '/favicon/favicon-16x16.png'} />

        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5" />

        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href="/manifest.json" />
        <Meta />
        <Links />
      </>);

  },
  { cleanup: false }
); // {cleanup: false} To fixed flashing image, due to remix-island hydration effect

/**
 *
 */
function App() {
  const location = useLocation();

  const GATRACKING = 'G-WST2QMJQVP';

  useEffect(() => {
    GaPageview(location.pathname, GATRACKING);
  }, [location]);

  const { state } = useNavigation();
  const refLoading = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (state === 'loading' || state === 'submitting')
    refLoading.current?.staticStart(50);
    if (state === 'idle') refLoading.current?.staticStart(100);
  }, [state]);

  return (
    <>
      <Head />
      {process.env.NODE_ENV === 'development' ? null :
      <Fragment>
          <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${GATRACKING}`} />

          <script
          async
          id="gtag-init"
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GATRACKING}', {
                  page_path: window.location.pathname,
                });
              `
          }} />

        </Fragment>}

      <LoadingBar color="#5957cc" ref={refLoading} />
      <ToastNotification />
      <div
        style={{
          backgroundImage:
          // eslint-disable-next-line
          "url('https://storage.googleapis.com/prod-flowai-profile-images/background-assets/bg-content.webp')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          zIndex: -1
        }} />

      <OnlineStatusProvider>
        <Outlet />
      </OnlineStatusProvider>
      <ScrollRestoration />
      <Scripts />
      <LiveReload />
    </>);

}

/**
 * The main component of the application.
 * @returns The application's main component.
 */
export default withSentry(App);

/**
 * The error boundary component for the application.
 * @returns The error boundary component.
 */
export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <html lang="en">
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div style={{ padding: 20 }}>
          <OwnErrorBoundary
            title="Something went wrong!"
            error={(error as Error)}
            isSmall />

        </div>
        <Scripts />
      </body>
    </html>);

}